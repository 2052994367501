import React from "react";

export default function Page() {
  return (
    <main className="container prose my-8 min-w-full">
      <h1>Privacy Policy</h1>
      <p>
        Soumha App (&quot;We&quot; &quot;Our&quot; or &quot;Us&quot;), Fahd Awad
        Mohsen Al-Ruwaili Cars Establishment (CR Number: 1009106962) is
        committed to protecting your privacy. This Privacy Policy explains how
        your personal information is collected, used, and disclosed by Soumha
        App. This Privacy Policy applies to our website, www.soumha.com, and its
        associated subdomains (collectively, our &quot;Service&quot;) alongside
        our mobile application collectively called “Platforms”. By accessing or
        using our Service, you signify that you have read. understood, and
        agreed to our collection, storage, use, and disclosure of your personal
        information as described in this Privacy Policy and our Terms of
        Service.
      </p>
      <p>
        This privacy policy governs the manner in which “Soumha App” collects,
        uses, maintains and discloses information collected by users of the
        Soumha App platform. This privacy policy applies to the platform and all
        products and services provided by Soumha App.
      </p>
      <h2>Interpretation</h2>
      <ul>
        <li>
          <strong>Company:</strong>
          when this policy mentions Company: “we”, “us” or &quot;our”, it refers
          to Soumha App, Fahd Awad Mohsen Al-Ruwaili Cars Establishment, which
          is responsible for your information under this Privacy Policy.
        </li>
        <li>
          <strong>Cookie:</strong>
          Small amount of data generated by a website and saved by your web
          browser. It is used to identify your browser, provide analytics, and
          remember information about you such as your language preference or
          login information.
        </li>
        <li>
          <strong>Country:</strong>
          where Soumha App runs its operations.
        </li>

        <li>
          <strong>Device:</strong>
          any internet connected device such as a phone, tablet, computer or any
          other device that can be used to visit Soumha and use the services.
        </li>
        <li>
          <strong>IP address:</strong>
          Every device connected to the Internet is assigned a number known as
          an Internet protocol (IP) address. These numbers are usually assigned
          in geographic blocks. An IP address can often be used to identify the
          location from which a device is connecting to the Internet.
        </li>
        <li>
          <strong>Personnel:</strong>
          refers to those individuals who are employed by Soumha App or its
          parent company PII or are under contract to perform a service on
          behalf of the parties.
        </li>
        <li>
          <strong>Personal Data:</strong>
          any information that directly, indirectly, or in connection with other
          information — including a personal identification number — allows for
          the identification or identifiability of a natural person.
        </li>
        <li>
          <strong>Service:</strong>
          refers to the service provided by Soumha App as described in the
          relative terms (if available) and on this platform.
        </li>
        <li>
          <strong>Third-party service:</strong>
          refers to advertisers, contest sponsors, promotional and marketing
          partners. and others who provide our content or whose products or
          services we think may interest you.
        </li>

        <li>
          <strong>Website:</strong>
          Soumha App&apos;s site, which can be accessed via this URL:
          www.soumha.com.
        </li>
        <li>
          <strong>You:</strong>a person or entity that is registered with Soumha
          to use the Services.
        </li>
      </ul>
      <h2>What Information Do We Collect?</h2>
      <p>
        Soumha App collects below personal identification information from users
        in various ways, including but not limited to when the user visits any
        of our Platforms, registering on them filling out an application, a
        survey analysis form, and other activities, services, features or
        resources that we may provide on our Platforms.
      </p>
      <ul>
        <li>Name / Username</li>
        <li>Phone Numbers </li>
        <li>Email Addresses</li>
        <li>Mailing Addresses</li>
        <li>Billing Addresses</li>
      </ul>
      <p>
        We also collect information from mobile devices for a better user
        experience, although these features are completely optional.
      </p>
      <p>
        Soumha App collects some information about users wherever they interact
        with the Platforms, and this information is not related to personal
        identity, but may include the type of smartphone and technical
        information about the user&apos;s methods of connecting to the platform,
        such as the company used to provide the Internet service and other
        similar information.
      </p>
      <h2>How Do We Use The Information We Collect?</h2>
      <p>
        Any of the information we collect from you may be used in one of the
        following ways:
      </p>
      <ul>
        <li>
          To personalize your experience (your information helps us to better
          respond to your individual needs)
        </li>
        <li>
          To improve customer service (your information helps us to more
          effectively respond to your customer service requests and support
          needs)
        </li>
        <li>To process transactions</li>
        <li>To administer a contest, promotion,survey or other site feature</li>
        <li>To send periodic emits</li>
      </ul>
      <h2>When do we use customer information from third parties?</h2>
      <p>
        We receive some information from the third parties when you contact us.
        For example, when you submit your email address to us to show interest
        in becoming our customer, we receive information from a third party that
        provides automated fraud detection services to us. We also occasionally
        collect information that is made publicly available on social media
        websites. You can control how much of your information social media
        websites make public by visiting these websites and changing your
        privacy settings.
      </p>
      <h2>Do we share the information we collect with third parties?</h2>
      <p>
        We may share the information that we collect. Both personal and
        non-personal, with third parties such as advertisers, contest sponsors,
        promotional and marketing partners, and others who provide our content
        or whose products or services we think may interest you. We may also
        share it with our current and future affiliated companies and business
        partners, and if we are involved in a merger, asset sale or other
        business reorganization, we may also share or transfer your personal and
        non-personal information to our successors-in-interest.
      </p>
      <p>
        We may engage trusted third party service providers to perform functions
        and provide services to us, such as hosting and maintaining our servers
        and our service, database storage and management, email management,
        storage marketing, credit card processing, customer service and
        fulfilling orders for products and services you may purchase through our
        platform. We will likely share your personal information, and possibly
        some non-personal information, with these third parties to enable them
        to perform these services for us and for you.
      </p>
      <p>
        We may share portions of our log file data, including IP addresses, for
        analytics purposes with third parties such as web analytics partners,
        application developers, and ad networks. If your IP address is shared,
        it may be used to estimate general location and other technographics
        such as connection speed, whether you have visited the service in a
        shared location, and type of device used to visit the service. They may
        aggregate information about our advertising and what you see on the
        service and then provide auditing, research and reporting for us and our
        advertisers.
      </p>
      <p>
        We may also disclose personal and non-personal information about you to
        government or law enforcement officials or private parties as we in our
        sole discretion believe necessary or appropriate in order to respond to
        claims, legal process (including subpoenas), to protect our rights and
        interests or those of a third party, the safety of the public or any
        person, to prevent or stop any illegal activity, unethical or legally
        actionable activity, or to otherwise comply with applicable court
        orders, laws, rules and regulations.
      </p>
      <h2>How Do We Use Your Email Address?</h2>
      <p>
        By submitting your email address on this both, you agree to receive
        emails from us. You can cancel your participation in any of these email
        lists at any time by clicking on the opt-out link or other unsubscribe
        option that is included in the respective email. We only send emails to
        people who have authorized us to contact them, either directly or
        through a third party. We do not send unsolicited commercial emails,
        because we hate spam as much as you do. By submitting your email
        address, you also agree to allow us to use your email address for
        customer audience targeting on sites Like Facebook, where we display
        custom advertising to specific people who have opted-in to receive
        communications from us. Email addresses submitted only through the order
        processing page will be used for the sole purpose of sending you
        information and updates pertaining to your order. If however, you have
        provided the same email to us through another method, we may use it for
        any of the purposes stated in this Policy.
      </p>
      <p>
        Note: If at any time you would like to unsubscribe from receiving future
        emails, we include detailed unsubscribe instructions at the bottom of
        each email.
      </p>
      <h2>Could my information be transferred to other countries?</h2>
      <p>
        We are incorporated in Oman. Information collected via our website,
        through direct interactions with you, or from use of our help services
        may be transferred from time to time to our offices or personnel, or to
        third parties, located throughout the world, and may be viewed and
        hosted anywhere in the world, including countries that may not have laws
        of general applicability regulating the use and transfer of such data.
        To the fullest extent allowed by applicable law, by using any of the
        above, you voluntarily consent to the trans-border transfer and hosting
        of such information.
      </p>
      <h2>Is the information collected through our service secure?</h2>
      <p>
        We take precautions to protect the security of your information. We have
        physical, electronic and managerial procedures to help safeguard,
        prevent unauthorized access, maintain data security and correctly use
        your information. However, neither people nor security systems are
        foolproof, including encryption systems. In addition, people can commit
        intentional crimes, make mistakes or fail to follow policies. Therefore,
        while we use reasonable efforts to protect your personal information, we
        cannot guarantee its absolute security. If applicable law imposes any
        non-disclaimable duty to protect your personal information, you agree
        that intentional misconduct will be the standards used to measure our
        compliance with that duty.
      </p>
      <h2>Can I update or correct my information?</h2>
      <p>
        The rights you have to request updates or corrections to the information
        we collect depend on your relationship with us. People may update or
        correct their information as detailed in our internal company employment
        policies. Customers have the right to request the restriction of certain
        uses and disclosures of personally identifiable information as follows.
        You can contact us in order to
      </p>
      <ul>
        <li>Update or correct your personally identifiable information.</li>
        <li>
          Change your preferences with respect to communications and other
          information you receive from us.
        </li>
        <li>
          Delete the personally identifiable information maintained about you on
          our systems (subject to the following paragraph), by canceling your
          account.
        </li>
      </ul>
      <p>
        Such updates, corrections, changes and deletions will have no effect on
        other information that we maintain, or information that we have provided
        to third parties in accordance with this Privacy Policy prior to such
        update, correction, change or deletion. To protect your privacy and
        security, we may take reasonable steps (such as requesting a unique
        password) to verify your identity before granting you profile access or
        making corrections. You are responsible for maintaining the secrecy of
        your unique password and account information at all times. You should be
        aware that it is not technologically possible to remove each and every
        record of the information you have provided to us from our system. The
        need to back up our systems to protect information from inadvertent loss
        means that a copy of your information may exist in a non-erasable form
        that will be difficult or impossible for us to locate. Promptly after
        receiving your request, all personal information stored in databases we
        actively use, and other readily searchable media will be updated,
        corrected, changed or deleted as soon as and to the extent reasonably
        and technically practicable. If you are an end user and wish to update,
        delete, or receive any information we have about you, you may do so by
        contacting the organization of which you are a customer.
      </p>
      <h2>Sale of Business</h2>
      <p>
        We reserve the right to transfer information to a third party in the
        event of a sale, merger or other transfer of all or substantially all of
        the assets of us or any of its Corporate Affiliates (as defined herein),
        or that portion of us or any of its Corporate Affiliates to which the
        Service relates, or in the event that we discontinue our business or
        file a petition or have filed against us a petition in bankruptcy,
        reorganization or similar proceeding, provided that the third party
        agrees to adhere to the terms of this Privacy Policy.
      </p>
      <h2>Affiliates</h2>
      <p>
        We may disclose information (including personal information) about you
        to our Corporate Affiliates. For purposes of this Privacy Policy,
        &quot;Corporate Affiliate&quot; means any person or entity which
        directly or indirectly controls, is controlled by or is under common
        control with us, whether by ownership or otherwise. Any information
        relating to you that we provide to our Corporate Affiliates will be
        treated by those Corporate Affiliates in accordance with the terms of
        this Privacy Policy.
      </p>
      <h2>How Long Do We Keep Your Information?</h2>
      <p>
        We keep your information only so long as we need it to provide service
        to you and fulfill the purposes described in this policy. This is also
        the case for anyone that we share your information with and who carries
        out services on our behalf. When we no longer need to use your
        information and there is no need for us to keep it to comply with our
        legal or regulatory obligations, we&apos;ll either remove it from our
        systems or depersonalize it so that we can&apos;t identify you.
      </p>
      <h2>How Do We Protect Your Information?</h2>
      <p>
        We implement a variety of security measures to maintain the safety of
        your personal information when you place an order or enter, submit,or
        access your personal information. We offer the use of a secure server.
        All supplied sensitive/credit information is transmitted via Secure
        Socket Layer (SSL) technology and then encrypted into our Payment
        gateway providers database only to be accessible by those authorized
        with special access rights to such systems and are required to keep the
        information confidential. After a transaction, your private information
        (credit cards, social security numbers, financials, etc.) is never kept
        on file. We cannot however, ensure or warrant the absolute security of
        any information you transmit to us or guarantee that your information on
        the Service may not be accessed, disclosed, altered, or destroyed by a
        breach of any of our physical, technical, or managerial safeguards.
      </p>
      <h2>Governing Law</h2>
      <p>
        The laws of the Country of sale, excluding its conflicts of law rules,
        shall govern this Agreement and your use of our service.
      </p>
      <h2>Your Consent</h2>
      <p>
        By using our service, registering an account or making a purchase, you
        consent to this Privacy Policy.
      </p>
      <h2>Links to Other Websites</h2>
      <p>
        This Privacy Policy applies only to the Services. The Services may
        contain links to other websites not operated or controlled by Soumha. We
        are not responsible for the content, accuracy or opinions expressed in
        such websites, and such websites are not investigated, monitored or
        checked for accuracy or completeness by us. Please remember that when
        you use a link to go from the Services to another website. our Privacy
        Policy is no longer in effect. Your browsing and interaction on any
        other website, including those that have a link on our platform, is
        subject to that website&apos;s own rules and policies. Such third
        parties may use their own cookies or other methods to collect
        information about you.
      </p>
      <h2>Payment Details</h2>
      <p>
        In respect to any debit or credit card or other payment processing
        details you have provided us, we acknowledge that this confidential
        information will not be stored with us anywhere.
      </p>
      <h2>Kids&apos; Privacy</h2>
      <p>
        We do not address anyone under the age of 18. We do not knowingly
        collect personally identifiable information from anyone under the age of
        18. If You are a parent or guardian and You are aware that Your child
        has provided Us with Personal Data, please contact Us. If We become
        aware that We have collected Personal Data from anyone under the age of
        18 without verification of parental consent, we take steps to remove
        that information from our servers.
      </p>
      <h2>Changes To Our Privacy Policy</h2>
      <p>
        If we decide to change our privacy policy, we will post those changes on
        this page, and update the Privacy Policy modification date.
      </p>
      <h2>Third-Party Services</h2>
      <p>
        We may display, include or make available third-party content (including
        data, information, applications and other products services) or provide
        links to third-party websites or services (&quot;Third- Party
        Services&quot;). You acknowledge and agree that we shall not be
        responsible for any Third-Party Services, including their accuracy,
        completeness, timeliness, validity, copyright compliance, legality,
        decency, quality or any other aspect thereof. We do not assume and shall
        not have any liability or responsibility to you or any other person or
        entity for any Third-Party Services. Third-Party Services and links
        thereto are provided solely as a convenience to you and you access and
        use them entirely at your own risk and subject to such third parties
        terms and conditions.
      </p>
      <h2>Tracking Technologies</h2>
      <ul>
        <li>
          <strong>Google Maps APIs:</strong>
          Google Maps API is a robust tool that can be used to create a custom
          map, a searchable map, check-in functions, display live data synching
          with location, plan routes, or create a mashup just to name a few.:
          <p>
            Google Maps API may collect information from You and from Your
            Device for security purposes.
          </p>
          <p>
            Google Maps API collects information that is held in accordance with
            its Privacy Policy
          </p>
        </li>
        <li>
          <strong>Cookies:</strong>
          We use Cookies to enhance the performance and functionality of our
          service but are non-essential to their use. However, without these
          cookies. certain functionality like videos may become unavailable or
          you would be required to enter your login details every time you visit
          our service as we would not be able to remember that you had logged in
          previously.
        </li>
        <li>
          <strong>Local Storage:</strong>
          Local Storage sometimes known as DOM storage, provides web apps with
          methods and protocols for storing client-side data. Web storage
          supports persistent data storage, similar to cookies but with a
          greatly enhanced capacity and no information stored in the HTTP
          request header
        </li>
        <li>
          <strong>Sessions:</strong>
          We use Sessions to identify the areas of Our Platforms that you have
          visited. A Session is a small piece of data stored on your computer or
          mobile device by your web browser.
        </li>
      </ul>
      <h2>Contact Us</h2>
      <p>
        Please don&apos;t hesitate to email us at
        <link href="mailto:support@soumha.com">support@soumha.com</link>
        if you have any questions.
      </p>
      <p>
        <strong>Last updated:</strong> 02 October 2024
      </p>
    </main>
  );
}
