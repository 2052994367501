import React from "react";

export default function TermsAndConditions() {
  return (
    <main className="container prose my-8 min-w-full">
      <h1>Terms and Conditions</h1>
      <p>
        By using the App, you acknowledge that you have read, understood, and
        agree to these Terms and Conditions. If you do not agree with any part
        of these terms, you may not use the App.
      </p>
      <h2>General</h2>
      <p>
        All terms and conditions contained in this agreement cannot be used
        against Soumha. In the event of any ambiguity or misunderstanding in a
        regulation, it cannot be refuted to negatively impact any party's
        interests. It is imperative to abide by the terms set forth herein by
        Soumha.
      </p>
      <p>
        Soumha has the right to determine, suspend, or terminate any services
        provided by the App and may prevent users from entering the site or
        remove any published material without warning if deemed necessary.
      </p>
      <p>
        Soumha reserves the right to suspend or terminate the account of the
        user, be it buyer, individual seller or a vehicle dealer (Merchant) for
        any violation of these terms.
      </p>
      <h2>Eligibility</h2>
      <p>
        You must be at least 18 years old and have the legal capacity to enter
        into a binding agreement to use our App. If you are accessing the
        application on behalf of a company or organization, you represent and
        warrant that you have the authority to bind that entity to these terms.
      </p>
      <h2>Account Registration</h2>
      <p>
        In order to use the App, you must create an account and provide accurate
        and complete information. You are responsible for maintaining the
        confidentiality of your account information and for all activities that
        occur under your account. You must notify us immediately of any
        unauthorized use or access to your account.
      </p>
      <h2>Subscriptions</h2>
      <p>
        The App offers three types of subscriptions: Freemium, Premium, and VIP.
        Each subscription has different features and benefits.
      </p>
      <p>
        By default, all user accounts, be it buyers, individual sellers or
        vehicle dealers (Merchants) are created with a Freemium subscription
        that allows limited bidding for single or limited auction(s) with higher
        commission and ownership transfer fees on any vehicle purchase or sale.
      </p>
      <p>
        Multiple plans are available for purchase under Premium and VIP
        subscriptions based on the count of auctions that you wish to
        participate in. By purchasing any of these plans, you agree to pay the
        applicable fees and acknowledge the features associated with each
        subscription. The count of auctions shall reduce by one (1) on each
        successful auction participation.
      </p>
      <p>
        By default, all merchant accounts are created with a Freemium
        subscription that allows unlimited listing of vehicles but with higher
        commission fees on any vehicle sale and no promotional and operational
        support.
      </p>
      <h2>Sellers</h2>
      <p>
        The App allows individuals and vehicle dealers ("Merchants") to list
        unlimited vehicles for auction. Sellers must provide accurate and
        complete information about the vehicles they are listing. The App
        reserves the right to verify the information provided by Sellers and may
        remove or suspend any listing that violates these terms.
      </p>
      <h2>Exclusive Cars & Wallet Deposit</h2>
      <p>
        Certain vehicles may be marked as "Exclusive" and can only be accessed
        by depositing a specific refundable amount into your wallet. By
        depositing the required amount, you gain access to bid on and purchase
        these Exclusive vehicles. Only subscribed users are allowed to
        participate in auctions on these Exclusive vehicles.
      </p>
      <p>
        The amount of this wallet deposit is decided by the vehicle dealers
        (“Merchants”) in collaboration with Soumha. Users are allowed to
        participate in any vehicle under the deposit category after paying its
        respective deposit. To participate in any other vehicle under a
        different deposit category for the same/different vehicle dealer
        (“Merchant”), users must add that respective wallet deposit separately.
      </p>
      <p>
        Wallet deposits made online via the App will reflect in your registered
        account immediately or within a maximum of 30-60 mins from the
        transaction time, allowing bidding, subject to transaction authorization
        by our payment integration platform “PayTabs”. Any failure in
        transactions or payment issues outside of our App on these third party
        platforms like “PayTabs” are beyond our control and Soumha is not
        responsible for them.
      </p>
      <p>
        Refunds of wallet deposits for Exclusive vehicles will be made according
        to the registration name via the App itself. If funds have been
        transferred by a different person or entity, the refund will be issued
        to the source account within 7-10 working days. Upon special request
        from the user, refunds may also be processed via bank transfer manually
        to a separate bank account as requested by the user which might take up
        to 10-15 working days.
      </p>
      <p>
        Refund of any deposit is disabled when the user is winning the auction
        on a vehicle that belongs to that deposit category. Users are also
        allowed to use this deposit amount while paying their vehicle payment
        and/or Soumha commission fees and/or ownership transfer fees after
        winning the auction but only if the vehicle belongs to that specific
        deposit category.
      </p>
      <p>
        The full deposit amount will be deducted in case of any violation of
        "Soumha" terms and conditions or if the user fails to pay for the
        vehicle won in the auction.
      </p>
      <h2>Featured Vehicles</h2>
      <p>
        Only VIP Sellers, be it individual or vehicle dealers, have the right to
        opt for featuring their vehicle on the home screen of our App for
        whatever duration as mutually agreed but limited to only one vehicle at
        a time per user. Such requests would be approved for whoever comes first
        and an additional fee may or may not be charged.
      </p>
      <h2>Auction Listings</h2>
      <p>
        By listing a vehicle, you represent and warrant that you have the legal
        right to sell it and that the information provided in the listing is
        accurate and complete. You are solely responsible for the content of
        your listings, including any images, descriptions, accepted price, and
        the type of Soumha commission fee that is applied on your vehicle sale,
        either in percentage of the vehicle value or a fixed value, which are
        both different as per the user subscription.
      </p>
      <p>
        A cover image for the vehicle is mandatory but we recommend that you add
        clear images of both the exterior and interior of the vehicle to allow
        the buying users to successfully bid on your vehicle auction. Any
        inappropriate images instead may lead to cancellation of the auction
        with a fine of up to 500 SAR and a temporary or permanent ban on Soumha
        platform.
      </p>
      <p>
        Vehicle location is mandatory and we recommend you to park the vehicle
        at the same location for the buying users to come and inspect it during
        your suggested hours. Any complaints of non-availability of the vehicle
        during the mentioned visiting hours may lead to cancellation of the
        auction with a fine of up to 1000 SAR and a temporary or permanent ban
        on Soumha platform.
      </p>
      <p>
        Any other incorrect vehicle information, be it minor or major, may also
        lead to cancellation of the auction with a fine of up to 1000 SAR and a
        temporary or permanent ban on Soumha platform.
      </p>
      <h2>Bidding and Transactions</h2>
      <p>
        Before bidding, all bidders must ensure that they regularly update their
        Soumha Apps and have the latest version installed. Failure to update the
        App may result in missing information or changes in services, and Soumha
        cannot be held responsible for any consequences arising from this.
      </p>
      <p>
        All vehicles will be sold "as is" in their current condition. Soumha
        does not guarantee any declarations, specifications, information, or
        particulars provided by the seller, including the accuracy of the
        odometer, ownership guarantees, information in the enclosed examination
        report, vehicle or equipment state, year of manufacture, vehicle or
        equipment exportability. It is the bidder's responsibility to check all
        data, advertisements, and information related to the state of the
        vehicle before bidding. All bidders must thoroughly check the vehicles
        to be purchased and ensure they match the presented specifications
        before bidding.
      </p>
      <p>
        All bidders must thoroughly check the vehicles to be purchased and
        ensure they match the presented specifications before bidding.
      </p>
      <p>
        Users can raise the auction value to bid on vehicles offered for sale
        through the App, ensuring that the value is not less than the minimum
        bid.
      </p>
      <p>
        The bidding time will be extended only in cases of technical issues or
        if a bid is placed in the last minutes of the bidding time to allow
        other bidders to re-submit their bids. For example, any bid on a vehicle
        within the last few minutes of the auction end time will result in an
        extension of the auction time.
      </p>
      <p>
        By placing a bid on the vehicle, you are committing to purchase that
        vehicle at the bid price if you are the winning bidder. Bids are binding
        and cannot be retracted. The App reserves the right to cancel or reject
        any bid deemed invalid or fraudulent. You agree to comply with the
        auction terms and conditions specified by the seller wherever necessary.
        All transactions are final, and you are responsible for timely payment
        and any applicable taxes including Soumha commission, ownership transfer
        and other additional fees. No more than 3 business days would be allowed
        for the user to pay the due amount of the vehicle value and the
        applicable Soumha commission fees with a mandatory ownership transfer
        fees and other additional fees. Any further delay in the payment would
        lead to a legal action, a temporary or permanent ban on our App and
        deduction of any applicable deposit amount made for participating in
        that vehicle auction. Soumha cannot guarantee the availability of the
        vehicle won in the auction beyond this timeline.
      </p>
      <p>
        All transactions will be conducted in Saudi Riyal (SAR). "Soumha" is not
        liable for any loss resulting from currency conversion or bank transfers
        between different currencies. As per the rule of the Government of the
        KSA, Value Added Tax (VAT) will be added to all auction prices (where
        applicable) and all charges, including Soumha commission, ownership
        transfer and other fees, etc. The App may offer various payment methods,
        and you are responsible for any transaction fees associated with your
        chosen payment method.
      </p>
      <p>
        "Soumha" is only authorized to provide a platform for vehicle trading
        through auction. After the payment, Soumha will communicate with the
        buyer and the Seller, individual or the vehicle dealer (“Merchant”), for
        the vehicle ownership transfer. Soumha shall hold the seller's
        receivable amount for the vehicle sale until both parties, buying user
        and the seller, individual selling user or the vehicle dealer
        (“Merchant”), confirm on the vehicle ownership transfer via the App and
        the vehicle is picked up from the Seller's location.
      </p>
      <p>
        Soumha reserves the right to cancel or extend the sale procedures before
        and/or after the auction ends, and before the vehicle ownership transfer
        is completed. Refund of the vehicle payment and any other additional
        fees paid after winning the auction, may be provided in certain
        circumstances, such as if the vehicle purchased is significantly
        different from its description or if the Seller fails to collaborate and
        confirm on the ownership transfer within 48 hours of the vehicle
        payment.
      </p>
      <p>
        In case if the buyer opts for the vehicle delivery service within the
        city, Soumha will deliver the vehicle within 24-48 hours after the
        vehicle ownership transfer is done and confirmed by both the Buyer and
        the Seller. In case if the buyer opts for the vehicle delivery service
        outside the city, Soumha will deliver the vehicle within 7-10 days based
        on the cities of the Buyer and the Seller, after the vehicle ownership
        transfer is done and confirmed by both the Buyer and the Seller.
      </p>
      <h2>Prohibited Activities</h2>
      <p>
        You agree not to engage in any activities that may violate applicable
        laws, regulations, or the rights of others. Prohibited activities
        include but are not limited to:
      </p>
      <ul>
        <li>Fraudulent bidding or manipulating auction results.</li>
        <li>
          Interfering with the application&apos;s functionality, security, or
          integrity.
        </li>
        <li>Posting false, misleading, or inappropriate content.</li>
        <li>
          Unauthorized access to other users&apos; accounts or personal
          information.
        </li>
        <li>Using the application for any illegal or unauthorized purposes.</li>
      </ul>
      <h2>Intellectual Property</h2>
      <p>
        All intellectual property rights in the App, including but not limited
        to trademarks, logos, and copyrighted materials, are owned by us or our
        licensors. You may not use, reproduce, modify, or distribute any of
        these materials without prior written permission.
      </p>
      <h2>Disclaimer of Warranties</h2>
      <p>
        Our App is provided on an "as is" and "as available" basis, without any
        warranties or representations, express or implied. We do not guarantee
        the accuracy, completeness, or reliability of any information or content
        provided within the application. Users acknowledge and agree that their
        use of the App is at their own risk.
      </p>
      <h2>Limitation of Liability</h2>
      <p>
        To the extent permitted by law, we shall not be liable for any indirect,
        incidental, consequential, or punitive damages arising out of or in
        connection with your use of the App, including but not limited to any
        transactions conducted through the App.
      </p>
      <h2>Indemnification</h2>
      <p>
        You agree to indemnify and hold us harmless from any claims, losses,
        liabilities, damages, costs, or expenses (including legal fees) arising
        out of or in connection with your use of the App, your violation of
        these terms, or your violation of any rights of third parties.
      </p>
      <h2>Governing Law</h2>
      <p>
        These terms and conditions shall be governed by and construed in
        accordance with the judicial system of the Kingdom of Saudi Arabia. Any
        disputes arising out of or in connection with these terms shall be
        subject to the exclusive jurisdiction of the courts of the Kingdom of
        Saudi Arabia.
      </p>
      <h2>Severability</h2>
      <p>
        If any provision of these terms and conditions is found to be invalid,
        illegal, or unenforceable, the remaining provisions shall continue in
        full force and effect.
      </p>
      <h2>Modification of Terms</h2>
      <p>
        We reserve the right to modify or update these terms and conditions at
        any time. Any changes will be effective immediately upon posting the
        revised terms within the application. Your continued use of the
        application after such changes constitutes your acceptance of the
        modified terms.
      </p>
      <p>
        If you have any questions or concerns about these terms and conditions,
        please contact us at support@soumha.com.
      </p>
      <p>
        <strong>Last updated:</strong> 02 October 2024
      </p>
    </main>
  );
}
