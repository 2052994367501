import React from "react";

export default function Page() {
  return (
    <main className="container prose my-8 min-w-full" dir="rtl">
      <h1>سياسة الخصوصية</h1>
      <p>
        تطبيق سومها (مؤسسة فهد عوض محيسن الرويلي للسيارات) سجل تجاري (
        1009106962) ملتزمون بحماية خصوصيتك. تشرح سياسة الخصوصية هذه كيفية جمع
        معلوماتك الشخصية واستخدامها والكشف عنها بواسطة تطبيق سومها . تنطبق سياسة
        الخصوصية هذه على موقعنا الإلكتروني،www.soumha.comوالنطاقات الفرعية
        المرتبطة بها (يُشار إليها إجمالاً باسم &quot;خدمتنا&quot;) جنبًا إلى جنب
        مع تطبيق الهاتف المحمول الخاص بنا والذي يُطلق عليه مجتمعًا
        &quot;المنصات&quot;. من خلال الوصول إلى خدمتنا أو استخدامها، فإنك تشير
        إلى أنك قد قرأت. فهمنا ووافقنا على جمع معلوماتك الشخصية وتخزينها
        واستخدامها والكشف عنها كما هو موضح في سياسة الخصوصية وشروط الخدمة الخاصة
        بنا.
      </p>
      <p>
        تحكم سياسة الخصوصية هذه الطريقة التي يقوم بها &quot;تطبيق ماهير&quot;
        بجمع واستخدام والحفاظ على والكشف عن المعلومات التي تم جمعها من قبل
        مستخدمي منصة تطبيق ماهير. تنطبق سياسة الخصوصية هذه على المنصة وجميع
        المنتجات والخدمات التي يقدمها تطبيق ماهير.
      </p>
      <h2>تفسير</h2>

      <ul>
        <li>
          <strong>شركة</strong>
          عندما تذكر هذه السياسة الشركة: &quot;نحن&quot; أو &quot;نا&quot; أو
          &quot;خاصتنا&quot;، فإنها تشير إلى تطبيق سومها, مؤسسة فهد عوض محيسن
          الرويلي للسيارات، المسؤولة عن معلوماتك بموجب سياسة الخصوصية هذه.
        </li>
        <li>
          <strong>بسكويت:</strong>
          عندما تذكر هذه السياسة الشركة: &quot;نحن&quot; أو &quot;نا&quot; أو
          &quot;خاصتنا&quot;، فإنها تشير إلى تطبيق سومها, مؤسسة فهد عوض محيسن
          الرويلي للسيارات، المسؤولة عن معلوماتك بموجب سياسة الخصوصية هذه.
        </li>
        <li>
          <strong>دولة:</strong>
          حيث يقوم تطبيق ماهير بتشغيل عملياته.
        </li>
        <li>
          <strong>•جهاز:</strong>
          أي جهاز متصل بالإنترنت مثل الهاتف أو الجهاز اللوحي أو الكمبيوتر أو أي
          جهاز آخر يمكن استخدامه لزيارة ماهير واستخدام الخدمات.
        </li>
        <li>
          <strong>عنوان IP:</strong>
          يتم تعيين رقم يعرف باسم عنوان بروتوكول الإنترنت (IP) لكل جهاز متصل
          بالإنترنت. عادةً ما يتم تعيين هذه الأرقام في كتل جغرافية. غالبًا ما
          يمكن استخدام عنوان IP لتحديد الموقع الذي يتصل منه الجهاز بالإنترنت.
        </li>
        <li>
          <strong>شؤون الموظفين:</strong>
          يشير إلى الأفراد الذين يعملون لدى تطبيق سومها, مؤسسة فهد عوض محيسن
          الرويلي للسيارات, أو بموجب عقد لأداء خدمة نيابة عن الطرفين.
        </li>
        <li>
          <strong>بيانات شخصية:</strong>
          أي معلومات تسمح بشكل مباشر أو غير مباشر أو فيما يتعلق بمعلومات أخرى -
          بما في ذلك رقم التعريف الشخصي - بتحديد هوية شخص طبيعي أو التعرف عليه.
        </li>
        <li>
          <strong>خدمة:</strong>
          يشير إلى الخدمة التي يقدمها تطبيق ماهير كما هو موضح في المصطلحات
          النسبية (إن وجدت) وعلى هذا النظام الأساسي.
        </li>
        <li>
          <strong>خدمة الطرف الثالث:</strong>
          يشير إلى المعلنين ورعاة المسابقة والشركاء الترويجيين والتسويقيين.
          والآخرين الذين يقدمون المحتوى الخاص بنا أو الذين نعتقد أن منتجاتهم أو
          خدماتهم قد تهمك.
        </li>
        <li>
          <strong>موقع إلكتروني:</strong>
          موقع تطبيق سومها، والذي يمكن الوصول إليه عبر عنوان URL هذا:
          www.soumha.com
        </li>
        <li>
          <strong>أنت:</strong>
          شخص أو كيان مسجل لدى ماهير لاستخدام الخدمات.
        </li>
      </ul>
      <h2>ما هي المعلومات التي نقوم بجمعها؟</h2>
      <p>
        يقوم تطبيق ماهير بجمع معلومات التعريف الشخصية أدناه من المستخدمين بطرق
        مختلفة، بما في ذلك على سبيل المثال لا الحصر، عندما يزور المستخدم أيًا من
        منصاتنا، والتسجيل عليها، وملء طلب، ونموذج تحليل الاستبيان، وغيرها من
        الأنشطة أو الخدمات أو الميزات أو الموارد التي قد نقدمها على منصاتنا.
      </p>
      <ul>
        <li>الاسم / اسم المستخدم</li>
        <li>أرقام الهواتف</li>
        <li>عناوين البريد الإلكتروني</li>
        <li>العناوين البريدية</li>
        <li>عناوين الفواتير</li>
      </ul>
      <p>
        نقوم أيضًا بجمع المعلومات من الأجهزة المحمولة للحصول على تجربة أفضل
        للمستخدم، على الرغم من أن هذه الميزات اختيارية تمامًا.
      </p>
      <p>
        يقوم تطبيق ماهير بجمع بعض المعلومات عن المستخدمين أينما تفاعلوا مع
        المنصات، ولا تتعلق هذه المعلومات بالهوية الشخصية، بل قد تشمل نوع الهاتف
        الذكي ومعلومات فنية حول طرق اتصال المستخدم بالمنصة، مثل الشركة المستخدمة
        في الاتصال بالمنصة. توفير خدمة الإنترنت وغيرها من المعلومات المماثلة.
      </p>
      <h2>كيف نستخدم المعلومات التي نجمعها؟</h2>
      <p>يمكن استخدام أي من المعلومات التي نجمعها منك بإحدى الطرق التالية:</p>
      <ul>
        <li>
          لتخصيص تجربتك (تساعدنا معلوماتك على الاستجابة بشكل أفضل لاحتياجاتك
          الفردية)
        </li>
        <li>
          لتحسين خدماتنا (نحن نسعى باستمرار لتحسين عروض خدماتنا بناءً على
          المعلومات والتعليقات التي نتلقاها منك)
        </li>
        <li>
          لتحسين خدمة العملاء (تساعدنا معلوماتك على الاستجابة بشكل أكثر فعالية
          لطلبات خدمة العملاء واحتياجات الدعم الخاصة بك)
        </li>
        <li>لمعالجة المعاملات</li>
        <li>لإدارة مسابقة أو ترويج أو استطلاع أو أي ميزة أخرى بالموقع</li>
        <li>إرسال انبعاثات دورية</li>
      </ul>
      <h2>متى نستخدم معلومات العملاء من أطراف ثالثة؟</h2>
      <p>
        نتلقى بعض المعلومات من الأطراف الثالثة عند الاتصال بنا. على سبيل المثال،
        عندما ترسل عنوان بريدك الإلكتروني إلينا لإظهار اهتمامك بأن تصبح عميلاً
        لدينا، فإننا نتلقى معلومات من طرف ثالث يوفر لنا خدمات الكشف التلقائي عن
        الاحتيال. نقوم أيضًا أحيانًا بجمع المعلومات التي يتم إتاحتها للجمهور على
        مواقع التواصل الاجتماعي. يمكنك التحكم في مقدار المعلومات التي تنشرها
        مواقع التواصل الاجتماعي للعامة من خلال زيارة هذه المواقع وتغيير إعدادات
        الخصوصية لديك.
      </p>
      <h2>هل نشارك المعلومات التي نجمعها مع أطراف ثالثة؟</h2>
      <p>
        يجوز لنا مشاركة المعلومات التي نجمعها. سواء الشخصية أو غير الشخصية، مع
        أطراف ثالثة مثل المعلنين ورعاة المسابقات والشركاء الترويجيين والتسويقيين
        وغيرهم ممن يقدمون المحتوى الخاص بنا أو الذين نعتقد أن منتجاتهم أو
        خدماتهم قد تهمك. يجوز لنا أيضًا مشاركتها مع الشركات التابعة لنا الحالية
        والمستقبلية وشركاء الأعمال، وإذا كنا منخرطين في عملية اندماج أو بيع أصول
        أو إعادة تنظيم أعمال أخرى، فقد نشارك أيضًا معلوماتك الشخصية وغير الشخصية
        أو ننقلها إلى خلفائنا. -اهتمام.
      </p>
      <p>
        قد نقوم بإشراك مقدمي خدمات خارجيين موثوقين لأداء الوظائف وتقديم الخدمات
        لنا، مثل استضافة وصيانة خوادمنا وخدماتنا وتخزين قاعدة البيانات وإدارتها
        وإدارة البريد الإلكتروني وتسويق التخزين ومعالجة بطاقات الائتمان وخدمة
        العملاء وتلبية طلبات المنتجات. والخدمات التي يمكنك شراؤها من خلال
        منصتنا. من المحتمل أن نشارك معلوماتك الشخصية، وربما بعض المعلومات غير
        الشخصية، مع هذه الأطراف الثالثة لتمكينها من أداء هذه الخدمات لنا ولكم.
      </p>
      <p>
        يجوز لنا مشاركة أجزاء من بيانات ملف السجل الخاص بنا، بما في ذلك عناوين
        IP، لأغراض التحليلات مع أطراف ثالثة مثل شركاء تحليلات الويب ومطوري
        التطبيقات وشبكات الإعلانات. إذا تمت مشاركة عنوان IP الخاص بك، فيمكن
        استخدامه لتقدير الموقع العام والتقنيات الأخرى مثل سرعة الاتصال، وما إذا
        كنت قد زرت الخدمة في موقع مشترك، ونوع الجهاز المستخدم لزيارة الخدمة. وقد
        يقومون بتجميع معلومات حول إعلاناتنا وما تراه على الخدمة ثم توفير التدقيق
        والبحث وإعداد التقارير لنا وللمعلنين لدينا.
      </p>
      <p>
        يجوز لنا أيضًا الكشف عن المعلومات الشخصية وغير الشخصية المتعلقة بك إلى
        الحكومة أو المسؤولين عن إنفاذ القانون أو الأطراف الخاصة وفقًا لتقديرنا
        الخاص الذي نعتقد أنه ضروري أو مناسب للرد على المطالبات والإجراءات
        القانونية (بما في ذلك مذكرات الاستدعاء) لحماية حقوقنا و مصالح أو مصالح
        طرف ثالث، أو سلامة الجمهور أو أي شخص، لمنع أو إيقاف أي نشاط غير قانوني،
        أو نشاط غير أخلاقي أو قابل للتنفيذ قانونًا، أو الامتثال لأوامر المحكمة
        والقوانين والقواعد واللوائح المعمول بها.
      </p>
      <h2>كيف نستخدم عنوان بريدك الإلكتروني؟</h2>
      <p>
        من خلال تقديم عنوان بريدك الإلكتروني على كليهما، فإنك توافق على تلقي
        رسائل البريد الإلكتروني منا. يمكنك إلغاء مشاركتك في أي من قوائم البريد
        الإلكتروني هذه في أي وقت عن طريق النقر على رابط إلغاء الاشتراك أو خيار
        إلغاء الاشتراك الآخر المضمن في البريد الإلكتروني المعني. نحن نرسل رسائل
        البريد الإلكتروني فقط إلى الأشخاص الذين سمحوا لنا بالاتصال بهم، إما
        مباشرة أو من خلال طرف ثالث. نحن لا نرسل رسائل بريد إلكتروني تجارية غير
        مرغوب فيها، لأننا نكره البريد العشوائي مثلك تمامًا. من خلال إرسال عنوان
        بريدك الإلكتروني، فإنك توافق أيضًا على السماح لنا باستخدام عنوان بريدك
        الإلكتروني لاستهداف جمهور العملاء على مواقع مثل Facebook، حيث نعرض
        إعلانات مخصصة لأشخاص محددين اختاروا تلقي الاتصالات منا. سيتم استخدام
        عناوين البريد الإلكتروني المقدمة فقط من خلال صفحة معالجة الطلب لغرض وحيد
        هو إرسال المعلومات والتحديثات المتعلقة بطلبك. ومع ذلك، إذا قمت بتقديم
        نفس البريد الإلكتروني إلينا من خلال طريقة أخرى، فقد نستخدمه لأي من
        الأغراض المذكورة في هذه السياسة.
      </p>
      <p>
        ملحوظة: إذا كنت ترغب في أي وقت في إلغاء الاشتراك في تلقي رسائل البريد
        الإلكتروني المستقبلية، فإننا ندرج تعليمات مفصلة لإلغاء الاشتراك في أسفل
        كل بريد إلكتروني.
      </p>
      <h2>هل يمكن نقل معلوماتي إلى بلدان أخرى؟</h2>
      <p>
        نحن مدرجون في عمان. المعلومات التي تم جمعها عبر موقعنا الإلكتروني، أو من
        خلال التفاعلات المباشرة معك، أو من خلال استخدام خدمات المساعدة الخاصة
        بنا، قد يتم نقلها من وقت لآخر إلى مكاتبنا أو موظفينا، أو إلى أطراف
        ثالثة، موجودة في جميع أنحاء العالم، ويمكن عرضها واستضافتها في أي مكان في
        العالم، بما في ذلك البلدان التي قد لا يكون لديها قوانين قابلة للتطبيق
        العام تنظم استخدام ونقل هذه البيانات. إلى أقصى حد يسمح به القانون
        المعمول به، وباستخدام أي مما سبق، فإنك توافق طوعًا على نقل هذه المعلومات
        واستضافتها عبر الحدود.
      </p>
      <h2>هل المعلومات التي يتم جمعها من خلال خدمتنا آمنة؟</h2>
      <p>
        نحن نتخذ الاحتياطات اللازمة لحماية أمن معلوماتك. لدينا إجراءات مادية
        وإلكترونية وإدارية للمساعدة في حماية معلوماتك ومنع الوصول غير المصرح به
        والحفاظ على أمان البيانات واستخدامها بشكل صحيح. ومع ذلك، لا الأشخاص ولا
        الأنظمة الأمنية مضمونة، بما في ذلك أنظمة التشفير. بالإضافة إلى ذلك، يمكن
        للناس أن يرتكبوا جرائم متعمدة، أو يرتكبوا أخطاء، أو يفشلوا في اتباع
        السياسات. لذلك، بينما نبذل جهودًا معقولة لحماية معلوماتك الشخصية، لا
        يمكننا ضمان أمانها المطلق. إذا كان القانون المعمول به يفرض أي واجب غير
        قابل للإخلاء لحماية معلوماتك الشخصية، فإنك توافق على أن سوء السلوك
        المتعمد سيكون المعايير المستخدمة لقياس امتثالنا لهذا الواجب.
      </p>
      <h2>هل يمكنني تحديث أو تصحيح معلوماتي؟</h2>
      <p>
        تعتمد حقوقك في طلب التحديثات أو التصحيحات على المعلومات التي نجمعها على
        علاقتك معنا. يجوز للأشخاص تحديث معلوماتهم أو تصحيحها على النحو المفصل في
        سياسات التوظيف الداخلية لشركتنا. يحق للعملاء طلب تقييد استخدامات معينة
        والإفصاح عن معلومات التعريف الشخصية على النحو التالي. يمكنك الاتصال بنا
        من أجل
      </p>
      <ul>
        <li>تحديث أو تصحيح معلومات التعريف الشخصية الخاصة بك.</li>
        <li>
          قم بتغيير تفضيلاتك فيما يتعلق بالاتصالات والمعلومات الأخرى التي
          تتلقاها منا.
        </li>
        <li>
          قم بحذف معلومات التعريف الشخصية المحفوظة عنك على أنظمتنا (مع مراعاة
          الفقرة التالية)، عن طريق إلغاء حسابك.
        </li>
      </ul>
      <p>
        لن يكون لهذه التحديثات والتصحيحات والتغييرات والحذف أي تأثير على
        المعلومات الأخرى التي نحتفظ بها، أو المعلومات التي قدمناها إلى أطراف
        ثالثة وفقًا لسياسة الخصوصية هذه قبل هذا التحديث أو التصحيح أو التغيير أو
        الحذف. لحماية خصوصيتك وأمانك، قد نتخذ خطوات معقولة (مثل طلب كلمة مرور
        فريدة) للتحقق من هويتك قبل منحك حق الوصول إلى ملفك الشخصي أو إجراء
        التصحيحات. أنت مسؤول عن الحفاظ على سرية كلمة المرور الفريدة الخاصة بك
        ومعلومات الحساب في جميع الأوقات. يجب أن تدرك أنه ليس من الممكن تقنيًا
        إزالة كل سجل من المعلومات التي قدمتها لنا من نظامنا. إن الحاجة إلى عمل
        نسخة احتياطية من أنظمتنا لحماية المعلومات من الفقد غير المقصود تعني أن
        نسخة من معلوماتك قد تكون موجودة في شكل غير قابل للمسح وسيكون من الصعب أو
        المستحيل بالنسبة لنا تحديد موقعه. فور تلقي طلبك، سيتم تحديث أو تصحيح أو
        تغيير أو حذف جميع المعلومات الشخصية المخزنة في قواعد البيانات التي
        نستخدمها بنشاط، وغيرها من الوسائط القابلة للبحث بسهولة، في أقرب وقت ممكن
        وإلى الحد المعقول والعملي من الناحية الفنية. إذا كنت مستخدمًا نهائيًا
        وترغب في تحديث أو حذف أو تلقي أي معلومات لدينا عنك، فيمكنك القيام بذلك
        عن طريق الاتصال بالمؤسسة التي أنت عميل فيها.
      </p>
      <h2>بيع الأعمال</h2>
      <p>
        نحن نحتفظ بالحق في نقل المعلومات إلى طرف ثالث في حالة البيع أو الدمج أو
        النقل الآخر لكل أو جزء كبير من أصولنا أو أي من الشركات التابعة لها (كما
        هو محدد هنا)، أو ذلك الجزء منا أو أي من الشركات التابعة لها التي تتعلق
        بها الخدمة، أو في حالة توقفنا عن العمل أو تقديم التماس أو تقديم التماس
        ضدنا في الإفلاس أو إعادة التنظيم أو إجراء مماثل، بشرط أن يوافق الطرف
        الثالث على الالتزام بالشروط من سياسة الخصوصية هذه.
      </p>
      <h2>الشركات التابعة</h2>
      <p>
        يجوز لنا الكشف عن معلومات (بما في ذلك المعلومات الشخصية) عنك للشركات
        التابعة لنا. لأغراض سياسة الخصوصية هذه، تعني عبارة &quot;الشركة
        التابعة&quot; أي شخص أو كيان يتحكم بشكل مباشر أو غير مباشر أو يخضع
        لسيطرة مشتركة معنا، سواء عن طريق الملكية أو غير ذلك. أي معلومات تتعلق بك
        نقدمها إلى شركتنا سيتم التعامل مع الشركات التابعة من قبل تلك الشركات
        التابعة وفقًا لشروط سياسة الخصوصية هذه.
      </p>
      <h2>ما المدة التي نحتفظ فيها بمعلوماتك؟</h2>
      <p>
        نحن نحتفظ بمعلوماتك فقط طالما أننا بحاجة إليها لتقديم الخدمة لك وتحقيق
        الأغراض الموضحة في هذه السياسة. وهذا هو الحال أيضًا بالنسبة لأي شخص
        نشارك معه معلوماتك وينفذ الخدمات نيابةً عنا. عندما لم نعد بحاجة إلى
        استخدام معلوماتك ولم تعد هناك حاجة لنا للاحتفاظ بها للامتثال لالتزاماتنا
        القانونية أو التنظيمية، فسنقوم إما بإزالتها من أنظمتنا أو إلغاء طابعها
        الشخصي حتى لا نتمكن من التعرف عليك.
      </p>
      <h2>كيف نحمي معلوماتك؟</h2>
      <p>
        نحن ننفذ مجموعة متنوعة من الإجراءات الأمنية للحفاظ على سلامة معلوماتك
        الشخصية عند تقديم طلب أو إدخال معلوماتك الشخصية أو إرسالها أو الوصول
        إليها. نحن نقدم استخدام خادم آمن. يتم نقل جميع المعلومات
        الحساسة/الائتمانية المقدمة عبر تقنية طبقة المقابس الآمنة (SSL) ثم يتم
        تشفيرها في قاعدة بيانات موفري بوابة الدفع لدينا فقط ليتم الوصول إليها من
        قبل الأشخاص المصرح لهم بحقوق وصول خاصة إلى هذه الأنظمة ويطلب منهم الحفاظ
        على سرية المعلومات. بعد إجراء المعاملة، لا يتم الاحتفاظ بمعلوماتك الخاصة
        (بطاقات الائتمان، وأرقام الضمان الاجتماعي، والبيانات المالية، وما إلى
        ذلك) في الملف أبدًا. ومع ذلك، لا يمكننا ضمان أو ضمان الأمان المطلق لأي
        معلومات ترسلها إلينا أو ضمان عدم إمكانية الوصول إلى معلوماتك الموجودة
        على الخدمة أو الكشف عنها أو تغييرها أو تدميرها عن طريق انتهاك أي من
        ضماناتنا المادية أو الفنية أو الإدارية. .
      </p>
      <h2>القانون الذي يحكم</h2>
      <p>
        تحكم قوانين بلد البيع، باستثناء قواعد تعارض القوانين، هذه الاتفاقية
        واستخدامك لخدمتنا.
      </p>
      <h2>موافقتك</h2>
      <p>
        باستخدام خدمتنا، أو تسجيل حساب أو إجراء عملية شراء، فإنك توافق على سياسة
        الخصوصية هذه.
      </p>
      <h2>روابط لمواقع أخرى</h2>
      <p>
        تنطبق سياسة الخصوصية هذه على الخدمات فقط. قد تحتوي الخدمات على روابط
        لمواقع أخرى لا تديرها أو تسيطر عليها ماهير. نحن لسنا مسؤولين عن المحتوى
        أو الدقة أو الآراء الواردة في هذه المواقع، ولا نقوم بالتحقيق في هذه
        المواقع أو مراقبتها أو التحقق من دقتها أو اكتمالها. يرجى تذكر أنه عند
        استخدام رابط للانتقال من الخدمات إلى موقع ويب آخر. سياسة الخصوصية الخاصة
        بنا لم تعد سارية. إن تصفحك وتفاعلك على أي موقع ويب آخر، بما في ذلك تلك
        التي لديها رابط على منصتنا، يخضع لقواعد وسياسات هذا الموقع. قد تستخدم
        هذه الأطراف الثالثة ملفات تعريف الارتباط الخاصة بها أو طرق أخرى لجمع
        معلومات عنك.
      </p>
      <h2>بيانات الدفع</h2>
      <p>
        فيما يتعلق بأي بطاقة خصم أو بطاقة ائتمان أو أي تفاصيل أخرى لمعالجة الدفع
        قدمتها لنا، فإننا نقر بأن هذه المعلومات السرية لن يتم تخزينها معنا في أي
        مكان.
      </p>
      <h2>خصوصية الأطفال</h2>
      <p>
        نحن لا نخاطب أي شخص يقل عمره عن 18 عامًا. ولا نجمع معلومات التعريف
        الشخصية عن قصد من أي شخص يقل عمره عن 18 عامًا. إذا كنت أحد الوالدين أو
        الوصي وأنت على علم بأن طفلك قد زودنا ببيانات شخصية، فيرجى الاتصال بنا
        نحن. إذا علمنا أننا قمنا بجمع بيانات شخصية من أي شخص يقل عمره عن 18
        عامًا دون التحقق من موافقة الوالدين، فإننا نتخذ خطوات لإزالة تلك
        المعلومات من خوادمنا.
      </p>
      <h2>التغييرات في سياسة الخصوصية الخاصة بنا</h2>
      <p>
        إذا قررنا تغيير سياسة الخصوصية الخاصة بنا، فسنقوم بنشر هذه التغييرات على
        هذه الصفحة، ونقوم بتحديث تاريخ تعديل سياسة الخصوصية.
      </p>
      <h2>خدمات الطرف الثالث</h2>
      <p>
        يجوز لنا عرض محتوى طرف ثالث أو تضمينه أو إتاحته (بما في ذلك البيانات
        والمعلومات والتطبيقات وخدمات المنتجات الأخرى) أو توفير روابط لمواقع أو
        خدمات طرف ثالث (&quot;خدمات الطرف الثالث&quot;). أنت تقر وتوافق على أننا
        يجب أن نقوم بذلك لا نكون مسؤولين عن أي خدمات تابعة لطرف ثالث، بما في ذلك
        دقتها واكتمالها وتوقيتها وصلاحيتها والامتثال لحقوق الطبع والنشر والشرعية
        واللياقة والجودة أو أي جانب آخر منها، ونحن لا نتحمل ولن نتحمل أي التزام
        أو مسؤولية تجاهك أو تجاه أي شخص آخر يتم توفير خدمات الطرف الثالث
        والروابط الخاصة بها لشخص أو كيان آخر فقط كوسيلة راحة لك ويمكنك الوصول
        إليها واستخدامها بالكامل على مسؤوليتك الخاصة وتخضع لشروط وأحكام الطرف
        الثالث.
      </p>
      <h2>تقنيات التتبع</h2>
      <ul>
        <li>
          <strong>واجهات برمجة تطبيقات خرائط جوجل:</strong>
          Google Maps API هي أداة قوية يمكن استخدامها لإنشاء خريطة مخصصة، أو
          خريطة قابلة للبحث، أو وظائف تسجيل الوصول، أو عرض مزامنة البيانات
          المباشرة مع الموقع، أو تخطيط المسارات، أو إنشاء مزيج على سبيل المثال
          لا الحصر.:
          <p>
            قد تقوم Google Maps API بجمع معلومات منك ومن جهازك لأغراض أمنية.
          </p>
          <p>
            تجمع Google Maps API المعلومات التي يتم الاحتفاظ بها وفقًا لسياسة
            الخصوصية الخاصة بها
          </p>
        </li>
        <li>
          <strong>بسكويت:</strong>
          نحن نستخدم ملفات تعريف الارتباط لتحسين أداء ووظائف خدمتنا ولكنها ليست
          ضرورية لاستخدامها. ومع ذلك، بدون ملفات تعريف الارتباط هذه. قد تصبح بعض
          الوظائف مثل مقاطع الفيديو غير متاحة أو سيُطلب منك إدخال تفاصيل تسجيل
          الدخول الخاصة بك في كل مرة تزور فيها خدمتنا لأننا لن نتمكن من تذكر أنك
          قمت بتسجيل الدخول مسبقًا.
        </li>
        <li>
          <strong>التخزين المحلي:</strong>
          التخزين المحلي، المعروف أحيانًا باسم تخزين DOM، يوفر لتطبيقات الويب
          أساليب وبروتوكولات لتخزين البيانات من جانب العميل. يدعم تخزين الويب
          تخزين البيانات المستمر، على غرار ملفات تعريف الارتباط ولكن بسعة معززة
          بشكل كبير ولا يتم تخزين أي معلومات في رأس طلب HTTP
        </li>
        <li>
          <strong>الجلسات:</strong>
          نحن نستخدم الجلسات لتحديد مناطق منصاتنا التي قمت بزيارتها. الجلسة
          عبارة عن جزء صغير من البيانات المخزنة على جهاز الكمبيوتر أو الجهاز
          المحمول الخاص بك بواسطة متصفح الويب الخاص بك.
        </li>
      </ul>
      <h2>اتصل بنا</h2>
      <p>
        من فضلك لا تتردد في مراسلتنا عبر البريد الإلكتروني على{" "}
        <link href="mailto:support@soumha.com">support@soumha.com</link>
        إذا كان لديك أية أسئلة.
      </p>
      <p>
        <strong>آخر تحديث:</strong> 02 أكتوبر 2024
      </p>
    </main>
  );
}
