"use client";

import React, { useState } from "react";

export default function DeleteAccount() {
  const [email, setEmail] = useState("");

  // Functions
  async function deleteAccountHandler() {
    if (!email || email.trim() === "") {
      alert("Enter Email");
      return;
    }

    if (!email.includes("@") || !email.includes(".")) {
      alert("Invalid Email");
      return;
    }

    try {
      const userExistRes = await fetch(
        "https://dev.soumha.com/api/users/exists",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email: email.trim(), mobile: null }),
        }
      );

      const data = (await userExistRes.json()) as {
        accountExists: boolean;
        buyer: { id: number };
        is_deleted: boolean;
      };

      if (data.accountExists && data.buyer && !data.is_deleted) {
        await fetch(
          `https://dev.soumha.com/api/users/delete-account?id=${data.buyer.id}`,
          {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
          }
        );

        alert("your account will be deleted in 30 days");
        return;
      }
      if (!data.accountExists && !data.buyer) {
        alert("Invalid email, account doesn't exist");
        return;
      }

      if (data.accountExists && data.is_deleted) {
        alert(
          "Invalid email, account is deleted as per your request, contact support"
        );
        return;
      }
    } catch (error) {
      alert("Failed to delete your account");
    }
  }

  return (
    <section className="flex justify-center items-center h-[100vh] bg-slate-200">
      <div className="shadow-md max-w-[600px] mx-auto px-3 py-2 bg-white">
        <h1 className="text-center text-2xl font-bold">Delete Your Account</h1>
        <p className="text-sm text-slate-600 text-center">
          your account will be deleted within 30days after you make a request,
          if you still want to comeback within the time limit, please contact
          support at info@fixezee.com
        </p>
        <div className="my-8 flex flex-col items-center space-y-4">
          <input
            type="email"
            name="email"
            id="email"
            placeholder="name@example.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-[280px] border border-solid border-black p-1"
          />
          <button
            onClick={deleteAccountHandler}
            className="bg-blue-600 px-4 py-2 font-bold text-gray-100 rounded-md"
          >
            Delete Account
          </button>
        </div>
      </div>
    </section>
  );
}
