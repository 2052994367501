import { createBrowserRouter } from "react-router-dom";
import Home from "./routes/home.tsx";
import PrivacyPolicy from "./routes/privacy-policy.tsx";
import TermsAndConditions from "./routes/terms-and-conditions.tsx";
import DeleteAccount from "./routes/delete-user-account.tsx";
import ArTermsAndConditions from "./routes/ar-terms-and-conditions.tsx";
import ArPrivacyPolicy from "./routes/ar-privacy-policy.tsx";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "terms-and-conditions",
    element: <TermsAndConditions />,
  },
  {
    path: "ar",
    children: [
      {
        path: "terms-and-conditions",
        element: <ArTermsAndConditions />,
      },
      {
        path: "privacy-policy",
        element: <ArPrivacyPolicy />,
      },
    ],
  },
  {
    path: "delete-user-account",
    element: <DeleteAccount />,
  },
]);
