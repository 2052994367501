import { useState, useEffect } from "react";
import instagram from "../../assets/images/instagram.png";
import facebook from "../../assets/images/facebook.png";
import twitter from "../../assets/images/twitter.png";
import linkedin from "../../assets/images/linkedin.png";
import youtube from "../../assets/images/youtube.png";
import tiktok from "../../assets/images/tiktok.png";
import snapchat from "../../assets/images/snapchat.png";

const Footer = () => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const deadline = "10/15/2024 16:00:00 GMT+03:00";

  const getTime = () => {
    const time = Date.parse(deadline) - Date.now();

    setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  };

  useEffect(() => {
    const interval = setInterval(() => getTime(deadline), 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {/* Desktop View */}
      <div className="lg:flex-row lg:flex hidden flex-col flex-1 lg:w-screen">
        {/* Contact Component */}
        <div className="w-full flex items-center justify-evenly lg:w-1/3">
          <div
            onClick={() => {
              window.location.href = "https://www.instagram.com/soumhaapp/";
            }}
            className="h-14 w-14 shadow-lg flex items-center justify-center bg-[#fffefd] rounded-full hover:cursor-pointer transition duration-500 hover:scale-125"
          >
            <img src={instagram} alt="social links" width="30" height="30" />
          </div>
          <div
            onClick={() => {
              window.location.href = "https://www.linkedin.com/company/soumha";
            }}
            className="h-14 w-14 shadow-lg flex items-center justify-center bg-[#fffefd] rounded-full hover:cursor-pointer transition duration-500 hover:scale-125"
          >
            <img src={linkedin} alt="social links" width="30" height="30" />
          </div>
          <div
            onClick={() => {
              window.location.href =
                "https://www.facebook.com/profile.php?id=61564838182953";
            }}
            className="h-14 w-14 shadow-lg flex items-center justify-center bg-[#fffefd] rounded-full hover:cursor-pointer transition duration-500 hover:scale-125"
          >
            <img
              src={facebook}
              className="ml-3"
              alt="social links"
              width="30"
              height="30"
            />
          </div>
          <div
            onClick={() => {
              window.location.href = "https://x.com/soumhaapp";
            }}
            className="h-14 w-14 shadow-lg flex items-center justify-center bg-[#fffefd] rounded-full hover:cursor-pointer transition duration-500 hover:scale-125"
          >
            <img src={twitter} alt="social links" width="30" height="30" />
          </div>
          <div
            onClick={() => {
              window.location.href = "https://www.youtube.com/@soumha";
            }}
            className="h-14 w-14 shadow-lg flex items-center justify-center bg-[#fffefd] rounded-full hover:cursor-pointer transition duration-500 hover:scale-125"
          >
            <img
              src={youtube}
              className="mt-2"
              alt="social links"
              width="30"
              height="30"
            />
          </div>

          <div
            onClick={() => {
              window.location.href = "https://www.tiktok.com/@soumhaapp";
            }}
            className="h-14 w-14 shadow-lg flex items-center justify-center bg-[#fffefd] rounded-full hover:cursor-pointer transition duration-500 hover:scale-125"
          >
            <img src={tiktok} alt="social links" width="30" height="30" />
          </div>
          <div
            onClick={() => {
              window.location.href = "https://www.snapchat.com/add/soumhaapp";
            }}
            className="h-14 w-14 shadow-lg flex items-center justify-center bg-[#fffefd] rounded-full hover:cursor-pointer transition duration-500 hover:scale-125"
          >
            <img src={snapchat} alt="social links" width="30" height="30" />
          </div>
        </div>
        {/* Timer Component */}
        <div className="flex flex-col bg-[#01569e] pb-2 justify-evenly items-center w-full lg:w-1/3">
          <div className="font-league-spartan font-light underline underline-offset-4 text-lg lg:text-2xl mt-1 text-[#fffefd]">
            We will be live in
          </div>
          <div className="flex items-center w-full justify-evenly mt-2">
            <div className="bg-white relative h-[60px] w-[60px] lg:h-[80px] lg:w-[80px] font-league-spartan rounded-full flex flex-col items-center justify-center text-2xl lg:text-5xl font-light">
              {days}
              <p className="absolute bottom-1 font-league-spartan lg:text-sm  text-xs font-normal">
                days
              </p>
            </div>
            <div className="bg-white relative h-[60px] w-[60px] lg:h-[80px] lg:w-[80px] font-league-spartan rounded-full flex flex-col items-center justify-center text-2xl lg:text-5xl font-light">
              {hours}
              <p className="absolute bottom-1 font-league-spartan lg:text-sm  text-xs font-normal">
                hours
              </p>
            </div>{" "}
            <div className="bg-white relative h-[60px] w-[60px] lg:h-[80px] lg:w-[80px] font-league-spartan rounded-full flex flex-col items-center justify-center text-2xl lg:text-5xl font-light">
              {minutes}
              <p className="absolute bottom-1 font-league-spartan lg:text-sm  text-xs font-normal">
                mins
              </p>
            </div>{" "}
            <div className="bg-white relative h-[60px] w-[60px] lg:h-[80px] lg:w-[80px] font-league-spartan rounded-full flex flex-col items-center justify-center text-2xl lg:text-5xl font-light">
              {seconds}
              <p className="absolute bottom-1 font-league-spartan lg:text-sm  text-xs font-normal">
                secs
              </p>
            </div>
          </div>
        </div>
        {/* Newsletter Component */}
        <div className="w-full lg:w-1/3 flex p-10 items-center overflow-hidden text-center justify-around">
          <div className="font-quick-sand italic pr-5 text-base font-bold">
            "Try it and you would ask for more"
            <br />
            "جربها وسوف تطلب المزيد"
          </div>
          {/* <div className="rotate-90">
            <img src={signature} alt="signature" height="200" width="200" />
          </div> */}
        </div>
      </div>
      {/* Mobile View */}
      <div className="flex flex-col lg:hidden items-center justify-start flex-1 w-screen">
        {/* Timer Component */}
        <div className="flex flex-col bg-[#01569e] pb-2 justify-evenly items-center w-full lg:w-1/3">
          <div className="font-league-spartan font-light underline underline-offset-4 text-lg lg:text-2xl mt-1 text-[#fffefd]">
            We will be live in
          </div>
          <div className="flex items-center w-full justify-evenly mt-2">
            <div className="bg-white relative h-[60px] w-[60px] lg:h-[80px] lg:w-[80px] font-league-spartan rounded-full flex flex-col items-center justify-center text-2xl lg:text-5xl font-light">
              {days}
              <p className="absolute bottom-1 font-league-spartan lg:text-sm  text-xs font-normal">
                days
              </p>
            </div>
            <div className="bg-white relative h-[60px] w-[60px] lg:h-[80px] lg:w-[80px] font-league-spartan rounded-full flex flex-col items-center justify-center text-2xl lg:text-5xl font-light">
              {hours}
              <p className="absolute bottom-1 font-league-spartan lg:text-sm  text-xs font-normal">
                hours
              </p>
            </div>{" "}
            <div className="bg-white relative h-[60px] w-[60px] lg:h-[80px] lg:w-[80px] font-league-spartan rounded-full flex flex-col items-center justify-center text-2xl lg:text-5xl font-light">
              {minutes}
              <p className="absolute bottom-1 font-league-spartan lg:text-sm  text-xs font-normal">
                mins
              </p>
            </div>{" "}
            <div className="bg-white relative h-[60px] w-[60px] lg:h-[80px] lg:w-[80px] font-league-spartan rounded-full flex flex-col items-center justify-center text-2xl lg:text-5xl font-light">
              {seconds}
              <p className="absolute bottom-1 font-league-spartan lg:text-sm  text-xs font-normal">
                secs
              </p>
            </div>
          </div>
        </div>
        {/* Contact Component */}
        <div className="w-[80%] flex my-3 items-center justify-evenly space-x-2 lg:w-1/3">
          <div
            onClick={() => {
              window.location.href = "https://www.instagram.com/soumhaapp/";
            }}
            className="h-10 w-10 shadow-lg flex items-center justify-center bg-[#fffefd] rounded-full hover:cursor-pointer transition duration-500 hover:scale-125"
          >
            <img src={instagram} alt="social links" width="15" height="15" />
          </div>
          <div
            onClick={() => {
              window.location.href = "https://www.linkedin.com/company/soumha";
            }}
            className="h-10 w-10 shadow-lg flex items-center justify-center bg-[#fffefd] rounded-full hover:cursor-pointer transition duration-500 hover:scale-125"
          >
            <img src={linkedin} alt="social links" width="15" height="15" />
          </div>
          <div
            target="_blank"
            onClick={() => {
              window.location.href =
                "https://www.facebook.com/profile.php?id=61564838182953";
            }}
            className="h-10 w-10 shadow-lg flex items-center justify-center bg-[#fffefd] rounded-full hover:cursor-pointer transition duration-500 hover:scale-125"
          >
            <img
              src={facebook}
              className="ml-3"
              alt="social links"
              width="15"
              height="15"
            />
          </div>
          <div
            onClick={() => {
              window.location.href = "https://x.com/soumhaapp";
            }}
            className="h-10 w-10 shadow-lg flex items-center justify-center bg-[#fffefd] rounded-full hover:cursor-pointer transition duration-500 hover:scale-125"
          >
            <img src={twitter} alt="social links" width="15" height="15" />
          </div>
          <div
            onClick={() => {
              window.location.href = "https://www.youtube.com/@soumha";
            }}
            className="h-10 w-10 shadow-lg flex items-center justify-center bg-[#fffefd] rounded-full hover:cursor-pointer transition duration-500 hover:scale-125"
          >
            <img
              src={youtube}
              className="mt-2"
              alt="social links"
              width="15"
              height="15"
            />
          </div>
          <div
            onClick={() => {
              window.location.href = "https://www.tiktok.com/@soumhaapp";
            }}
            className="h-10 w-10 shadow-lg flex items-center justify-center bg-[#fffefd] rounded-full hover:cursor-pointer transition duration-500 hover:scale-125"
          >
            <img
              src={tiktok}
              className="mt-2"
              alt="social links"
              width="15"
              height="15"
            />
          </div>
          <div
            onClick={() => {
              window.location.href = "https://www.snapchat.com/add/soumhaapp";
            }}
            className="h-10 w-10 shadow-lg flex items-center justify-center bg-[#fffefd] rounded-full hover:cursor-pointer transition duration-500 hover:scale-125"
          >
            <img
              src={snapchat}
              className="mt-2"
              alt="social links"
              width="15"
              height="15"
            />
          </div>
        </div>
        <div className="w-full lg:w-1/3 flex p-10 items-center overflow-hidden text-center justify-around">
          <div className="font-quick-sand italic pr-5 text-base font-bold">
            "Try it and you would ask for more"
            <br />
            "جربها وسوف تطلب المزيد"
          </div>
          {/* <div className="rotate-90">
            <img src={signature} alt="signature" height="200" width="200" />
          </div> */}
        </div>
        {/* Newsletter Component */}
        {/* <div className="w-full lg:w-1/3 flex items-center mt-5 lg:mt-0 justify-center"> */}
        {/* Input */}
        {/* <div className="bg-gray-500 flex items-center justify-center lg:h-12 h-8 w-44 lg:w-52 mr-5">
            <input
              className="lg:h-[46px] h-[30px] w-[174px] lg:w-[206px] border placeholder:text-sm px-2 text-sm text-gray-500 bg-white"
              type="text"
              placeholder="Your E-mail"
            />
          </div> */}
        {/* Button */}
        {/* <div className="bg-[#2187EA] lg:h-12 h-8 w-20 lg:w-36 flex items-center justify-center hover:cursor-pointer lg:text-xl text-sm text-[#FFF1F1] font-league-spartan">
            Subscribe
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Footer;
