import React from "react";
import Footer from "../components/Footer/Footer";
import Hero from "../components/Hero/Hero";

export default function Home() {
  return (
    <div className="fle overflow-x-hidden overflow-y-hidden flex-col h-screen w-screen">
      <Hero />
      <Footer />
    </div>
  );
}
