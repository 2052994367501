import { useState, useEffect, useRef } from "react";
// import image1 from "../../assets/images/Hero-soumha.jpg";
// import image2 from "../../assets/images/image2.png";
import image3 from "../../assets/images/web3.png";
import mobile from "../../assets/images/mobile2.png";
// import heroMobile1 from "../../assets/images/Hero-mobile-1.jpg";
import heroMobile2 from "../../assets/images/Hero-mobile-2.jpg";

const Hero = () => {
  const slideRef = useRef();

  const images = [image3];
  const imagesMobile = [mobile, heroMobile2];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((currentIndex + 1) % images.length);
    }, 5000);
    return () => clearInterval(intervalId);
  }, [currentIndex, images.length]);

  return (
    <div className="lg:h-[82%] h-[65%] relative w-screen flex items-center justify-center">
      {/* Desktop View Img */}
      <img
        ref={slideRef}
        src={images[currentIndex]}
        className="lg:block lg:h-full lg:w-full lg:object-cover lg:transition lg:duration-500 lg:ease-in-out lg:transform hidden"
        alt="Images"
      />
      {/* Mobile View Img */}
      <img
        ref={slideRef}
        src={imagesMobile[currentIndex]}
        className="h-full w-full object-fill transition duration-500 ease-in-out transform block lg:hidden"
        alt="Images"
      />
    </div>
  );
};

export default Hero;
