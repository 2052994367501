import React from "react";

export default function ArTermsAndConditions() {
  return (
    <main className="container prose my-8 min-w-full" dir="rtl">
      <h1>الشروط والأحكام</h1>
      <p>
        باستخدام التطبيق، فإنك تقر بأنك قد قرأت وفهمت ووافقت على هذه الشروط
        والأحكام. إذا كنت لا توافق على أي جزء من هذه الشروط، فلا يجوز لك استخدام
        التطبيق.
      </p>
      <h2>عام</h2>
      <p>
        لا يمكن استخدام كافة الشروط والأحكام الواردة في هذه الاتفاقية ضد سومها.
        في حالة وجود أي غموض أو سوء فهم في اللائحة، لا يمكن دحضه بما يؤثر سلبًا
        على مصالح أي طرف. ومن الضروري الالتزام بالشروط المنصوص عليها في هذه
        الوثيقة من قبل سومها.
      </p>
      <p>
        يحق لسومها تحديد أو تعليق أو إنهاء أي خدمات يقدمها التطبيق ويجوز لها منع
        المستخدمين من دخول الموقع أو إزالة أي مواد منشورة دون سابق إنذار إذا لزم
        الأمر.
      </p>
      <p>
        تحتفظ سومها بالحق في تعليق أو إنهاء حساب المستخدم، سواء كان مشتريًا أو
        بائعًا فرديًا أو تاجر مركبات (تاجر) بسبب أي انتهاك لهذه الشروط.
      </p>
      <h2>الأهلية</h2>
      <p>
        يجب أن يكون عمرك 18 عامًا على الأقل وأن تتمتع بالأهلية القانونية لإبرام
        اتفاقية ملزمة لاستخدام تطبيقنا. إذا كنت تقوم بالوصول إلى التطبيق نيابةً
        عن شركة أو مؤسسة، فإنك تقر وتضمن أن لديك السلطة لإلزام هذا الكيان بهذه
        الشروط.
      </p>
      <h2>تسجيل الحساب</h2>
      <p>
        من أجل استخدام التطبيق، يجب عليك إنشاء حساب وتقديم معلومات دقيقة وكاملة.
        أنت مسؤول عن الحفاظ على سرية معلومات حسابك وعن جميع الأنشطة التي تتم ضمن
        حسابك. يجب عليك إخطارنا على الفور بأي استخدام أو وصول غير مصرح به إلى
        حسابك.
      </p>
      <h2>الاشتراكات</h2>
      <p>
        يقدم التطبيق ثلاثة أنواع من الاشتراكات: Freemium وPremium وVIP. كل
        اشتراك له ميزات وفوائد مختلفة.
      </p>
      <p>
        افتراضيًا، يتم إنشاء جميع حسابات المستخدمين، سواء كانوا مشترين أو بائعين
        أفراد أو تجار مركبات (التجار) من خلال اشتراك Freemium الذي يسمح بتقديم
        عطاءات محدودة لمزاد (مزادات) فردي أو محدود مع عمولة أعلى ورسوم نقل
        الملكية على أي عملية شراء أو بيع للمركبة .
      </p>
      <p>
        تتوفر خطط متعددة للشراء ضمن اشتراكات Premium وVIP بناءً على عدد المزادات
        التي ترغب في المشاركة فيها. من خلال شراء أي من هذه الخطط، فإنك توافق على
        دفع الرسوم المطبقة وتقر بالميزات المرتبطة بها. مع كل اشتراك. يجب أن
        ينخفض ​​عدد المزادات بمقدار واحد (1) عند كل مشاركة ناجحة في المزاد.
      </p>
      <p>
        افتراضيًا، يتم إنشاء جميع حسابات التجار من خلال اشتراك Freemium الذي
        يسمح بإدراج غير محدود للمركبات ولكن مع رسوم عمولة أعلى على أي بيع مركبة
        ولا يوجد دعم فني وتشغيلي.
      </p>
      <h2>البائعين</h2>
      <p>
        يتيح التطبيق للأفراد وتجار المركبات ("التجار") القيام بذلك قائمة عدد غير
        محدود من المركبات للمزاد. يجب على البائعين تقديم معلومات دقيقة وكاملة عن
        المركبات التي يقومون بإدراجها. يحتفظ التطبيق بالحق في التحقق من
        المعلومات المقدمة من البائعين ويجوز له إزالة أو تعليق أي قائمة تنتهك هذه
        الشروط.
      </p>
      <h2>المركبات الحصرية وإيدعات في المحفظة</h2>
      <p>
        قد يتم وضع علامة على بعض المركبات على أنها "حصرية" ولا يمكن الوصول إليها
        إلا عن طريق إيداع مبلغ محدد قابل للاسترداد في محفظتك. من خلال إيداع
        المبلغ المطلوب، يمكنك الوصول إلى المزايدة على هذه المركبات الحصرية
        وشرائها. يُسمح فقط للمستخدمين المشتركين بالمشاركة في المزادات على هذه
        العروض الحصرية ق.
      </p>
      <p>
        يتم تحديد مبلغ إيداع المحفظة هذا من قبل تجار المركبات ("التجار")
        بالتعاون مع سومها. يُسمح للمستخدمين بالمشاركة في أي مركبة ضمن فئة
        الوديعة بعد دفع الوديعة الخاصة بها. للمشاركة في أي مركبة أخرى ضمن فئة
        إيداع مختلفة لنفس تاجر المركبات/المختلف ("التاجر")، يجب على المستخدمين
        إضافة إيداع المحفظة المعني بشكل منفصل.
      </p>
      <p>
        سوف تنعكس إيداعات المحفظة التي تتم عبر الإنترنت من خلال التطبيق في حسابك
        المسجل فورًا أو خلال مدة أقصاها 30-60 دقيقة من وقت المعاملة، مما يسمح
        بتقديم العطاءات، ويخضع لترخيص المعاملة من خلال منصة تكامل الدفع الخاصة
        بنا "PayTabs". أي فشل في المعاملات أو مشكلات الدفع خارج تطبيقنا على
        منصات الطرف الثالث مثل "PayTabs" هو خارج عن سيطرتنا وسومها ليست مسؤولة
        عنها.
      </p>
      <p>
        سيتم استرداد إيداعات المحفظة للمركبات الحصرية وفقًا لاسم التسجيل عبر
        التطبيق نفسه. إذا تم تحويل الأموال من قبل شخص أو كيان آخر، فسيتم رد
        المبلغ إلى حساب المصدر خلال 7 إلى 10 أيام عمل. بناءً على طلب خاص من
        المستخدم، يمكن أيضًا معالجة المبالغ المستردة عن طريق التحويل المصرفي
        يدويًا إلى حساب مصرفي منفصل حسب طلب المستخدم، الأمر الذي قد يستغرق ما
        يصل إلى 10-15 يوم عمل.
      </p>
      <p>
        يتم تعطيل استرداد أي إيداع عندما يفوز المستخدم بالمزاد على مركبة تنتمي
        إلى فئة الإيداع تلك. يُسمح للمستخدمين أيضًا باستخدام مبلغ الإيداع هذا
        أثناء دفع رسوم سيارتهم و/أو رسوم عمولة سومها و/أو رسوم نقل الملكية بعد
        الفوز بالمزاد ولكن فقط إذا كانت السيارة تنتمي إلى فئة الإيداع المحددة
        تلك.
      </p>
      <p>
        سيتم خصم مبلغ الوديعة بالكامل في حالة حدوث أي انتهاك لشروط وأحكام "سومة"
        أو في حالة فشل المستخدم في دفع ثمن السيارة التي فاز بها في المزاد.
      </p>
      <h2>المركبات المميزة</h2>
      <p>
        يحق فقط للبائعين المهمين، سواء كانوا أفرادًا أو تجار مركبات، اختيار عرض
        سيارتهم على الشاشة الرئيسية لتطبيقنا لأي مدة يتم الاتفاق عليها بشكل
        متبادل ولكنها تقتصر على مركبة واحدة فقط في كل مرة لكل مستخدم. ستتم
        الموافقة على مثل هذه الطلبات لمن يأتي أولاً وقد يتم أو لا يتم فرض رسوم
        إضافية.
      </p>
      <h2>قوائم المزاد</h2>
      <p>
        من خلال إدراج مركبة، فإنك تقر وتضمن أن لديك الحق القانوني في بيعها وأن
        المعلومات المقدمة في القائمة دقيقة وكاملة. أنت وحدك المسؤول عن محتوى
        قوائمك، بما في ذلك أي صور وأوصاف وسعر مقبول ونوع رسوم عمولة سومها التي
        يتم تطبيقها على بيع سيارتك، إما كنسبة مئوية من قيمة السيارة أو قيمة
        ثابتة، وكلاهما مختلفة حسب اشتراك المستخدم.
      </p>
      <p>
        تعد صورة الغلاف للمركبة إلزامية ولكننا نوصي بإضافة صور واضحة لكل من
        الجزء الخارجي والداخلي للمركبة للسماح للمستخدمين المشترين بالمزايدة
        بنجاح على مزاد سيارتك. وأي صور غير لائقة بدلاً من ذلك قد تؤدي إلى إلغاء
        المزاد مع غرامة تصل إلى 500 ريال سعودي وحظر مؤقت أو دائم على منصة سومها.
      </p>
      <p>
        يعد موقع السيارة إلزاميًا ونوصيك بإيقاف السيارة في نفس الموقع حتى يأتي
        المستخدمون المشترون ويفحصونها خلال الساعات المقترحة. أي شكوى بشأن عدم
        توافر المركبة خلال ساعات الزيارة المذكورة قد تؤدي إلى إلغاء المزاد مع
        غرامة تصل إلى 1000 ريال سعودي وحظر مؤقت أو دائم على منصة سومها.
      </p>
      <p>
        أي معلومات أخرى غير صحيحة عن المركبة، سواء كانت بسيطة أو كبيرة، قد تؤدي
        أيضًا إلى إلغاء المزاد مع غرامة تصل إلى 1000 ريال سعودي وحظر مؤقت أو
        دائم على منصة سومها.
      </p>
      <h2>العطاءات والمعاملات</h2>
      <p>
        قبل تقديم العطاءات، يجب على جميع مقدمي العروض التأكد من تحديث تطبيقات
        Soumha بانتظام وتثبيت أحدث إصدار. قد يؤدي الفشل في تحديث التطبيق إلى
        فقدان معلومات أو تغييرات في الخدمات، ولا يمكن تحميل سومها المسؤولية عن
        أي عواقب تنشأ عن ذلك.
      </p>
      <p>
        سيتم بيع جميع المركبات "كما هي" في حالتها الحالية. ولا تضمن سومها أي
        إقرارات أو مواصفات أو معلومات أو تفاصيل يقدمها البائع، بما في ذلك دقة
        عداد المسافات، وضمانات الملكية، والمعلومات الواردة في تقرير الفحص
        المرفق، وحالة المركبة أو المعدات، وسنة الصنع، وقابلية تصدير المركبة أو
        المعدات. وتقع على عاتق مقدم العطاء مسؤولية التحقق من كافة البيانات
        والإعلانات والمعلومات المتعلقة بحالة المركبة قبل المزايدة.
      </p>
      <p>
        يجب على جميع مقدمي العروض فحص المركبات التي سيتم شراؤها بدقة والتأكد من
        مطابقتها للمواصفات المقدمة قبل تقديم العطاءات.
      </p>
      <p>
        يمكن للمستخدمين رفع قيمة المزاد للمزايدة على المركبات المعروضة للبيع من
        خلال التطبيق، مع التأكد من أن القيمة لا تقل عن الحد الأدنى للمزايدة.
      </p>
      <p>
        سيتم تمديد وقت تقديم العطاءات فقط في حالات المشكلات الفنية أو إذا تم
        تقديم عرض في الدقائق الأخيرة من وقت تقديم العطاءات للسماح لمقدمي العروض
        الآخرين بإعادة تقديم عروضهم. على سبيل المثال، أنيويورك محاولة على سيارة
        خلال القليلة الماضية محضر وقت انتهاء المزاد سيؤدي إلى تمديد وقت المزاد.
      </p>
      <p>
        من خلال تقديم عرض على السيارة، فإنك تلتزم بشراء تلك السيارة بسعر العرض
        إذا كنت صاحب العرض الفائز. العطاءات ملزمة ولا يمكن التراجع عنها. يحتفظ
        التطبيق بالحق في إلغاء أو رفض أي عرض يعتبر غير صالح أو احتيالي. أنت
        توافق على الالتزام بشروط وأحكام المزاد التي يحددها البائع حيثما كان ذلك
        ضروريًا. جميع المعاملات نهائية، وأنت مسؤول عن الدفع في الوقت المناسب وأي
        ضرائب مطبقة بما في ذلك عمولة سومها ونقل الملكية والرسوم الإضافية الأخرى.
        لا يزيد عن 3 سيتم السماح للمستخدم بدفع المبلغ المستحق من قيمة السيارة
        ورسوم عمولة سومها المطبقة مع رسوم نقل الملكية الإلزامية وغيرها من الرسوم
        الإضافية.. أي تأخير إضافي في الدفع سيؤدي إلى اتخاذ إجراء قانوني وحظر
        مؤقت أو دائم على تطبيقنا وخصم أي مبلغ إيداع قابل للتطبيق تم إجراؤه
        للمشاركة في مزاد السيارة هذا. لا تستطيع سومها ضمان توافر السيارة التي
        فازت بها في المزاد بعد هذا الجدول الزمني.
      </p>
      <p>
        سيتم إجراء جميع المعاملات بالريال السعودي (SAR). "صومة" ليست مسؤولة عن
        أي خسارة تنتج عن تحويل العملات أو التحويلات البنكية بين العملات
        المختلفة. وفقًا لقواعد حكومة المملكة العربية السعودية، سيتم إضافة ضريبة
        القيمة المضافة (VAT) إلى جميع أسعار المزاد (حيثما ينطبق ذلك) وجميع
        الرسوم، بما في ذلك عمولة السومحة ونقل الملكية والرسوم الأخرى.وما إلى
        ذلك. قد يقدم التطبيق طرق دفع مختلفة، وأنت مسؤول عن أي رسوم معاملات
        مرتبطة بطريقة الدفع التي اخترتها.
      </p>
      <p>
        "سومها" مخولة فقط بتوفير منصة لتجارة المركبات من خلال المزاد العلني. بعد
        الدفع سوف تقوم سومها بالتواصل مع المشتري و البائع أو الفرد أو تاجر
        المركبات ("التاجر")، ل نقل ملكية المركبة. تحتفظ سومها بالمبلغ المستحق
        للبائع مقابل بيع السيارة حتى يؤكد الطرفان، المستخدم المشتري والبائع،
        المستخدم البائع الفردي أو تاجر السيارة ("التاجر")، على نقل ملكية السيارة
        عبر التطبيق ويتم استلام السيارة من موقع البائع.
      </p>
      <p>
        تحتفظ سومها بالحق في إلغاء أو تمديد إجراءات البيع قبل و/أو بعد انتهاء
        المزاد، وقبل إتمام عملية نقل ملكية المركبة. قد يتم استرداد مبلغ السيارة
        وأي رسوم إضافية أخرى مدفوعة بعد الفوز بالمزاد في ظروف معينة، مثل إذا
        كانت السيارة المشتراة مختلفة بشكل كبير عن وصفها أو إذا فشل البائع في
        التعاون والتأكيد على نقل الملكية خلال 48 ساعة من دفع السيارة.
      </p>
      <p>
        في حالة اختيار المشتري خدمة توصيل السيارة داخل المدينة، ستقوم سومها
        بتسليم السيارة خلال 24-48 ساعة بعد إتمام عملية نقل ملكية السيارة
        وتأكيدها من قبل كل من المشتري والبائع. في حالة اختيار المشتري خدمة توصيل
        السيارة خارج المدينة، ستقوم سومها بتسليم السيارة خلال 7-10 أيام بناءً
        على مدينتي المشتري والبائع، بعد أن يتم نقل ملكية السيارة وتأكيدها من قبل
        كل من المشتري والمشتري. البائع.
      </p>
      <h2>الأنشطة المحظورة</h2>
      <p>
        أنت توافق على عدم المشاركة في أي أنشطة قد تنتهك القوانين أو اللوائح
        المعمول بها أو حقوق الآخرين. تشمل الأنشطة المحظورة، على سبيل المثال لا
        الحصر، ما يلي:
      </p>
      <ul>
        <li>المزايدة الاحتيالية أو التلاعب بنتائج المزاد.</li>
        <li>التدخل في وظائف التطبيق أو امنه أو سلامته.</li>
        <li>نشر محتوى كاذب أو مضلل أو غير لائق.</li>
        <li>
          الوصول غير المصرح به إلى حسابات المستخدمين الآخرين أو المعلومات
          الشخصية.
        </li>
        <li>استخدام التطبيق لأية أغراض غير قانونية أو غير مصرح بها.</li>
      </ul>
      <h2>الملكية الفكرية</h2>
      <p>
        جميع حقوق الملكية الفكرية في التطبيق، بما في ذلك على سبيل المثال لا
        الحصر العلامات التجارية والشعارات والمواد المحمية بحقوق الطبع والنشر،
        مملوكة لنا أو لمرخصينا. لا يجوز لك استخدام أو إعادة إنتاج أو تعديل أو
        توزيع أي من هذه المواد دون الحصول على إذن كتابي مسبق.
      </p>
      <h2>إخلاء المسؤولية عن الضمانات</h2>
      <p>
        يتم توفير تطبيقنا "كما هو" و"كما هو متاح"، دون أي ضمانات أو إقرارات،
        صريحة أو ضمنية. نحن لا نضمن دقة أو اكتمال أو موثوقية أي معلومات أو محتوى
        مقدم داخل التطبيق. يقر المستخدمون ويوافقون على أن استخدامهم للتطبيق يقع
        على مسؤوليتهم الخاصة.
      </p>
      <h2>حدود المسؤولية</h2>
      <p>
        إلى الحد الذي يسمح به القانون، لن نكون مسؤولين عن أي أضرار غير مباشرة أو
        عرضية أو تبعية أو تأديبية تنشأ عن أو فيما يتعلق باستخدامك للتطبيق، بما
        في ذلك على سبيل المثال لا الحصر، أي معاملات تتم من خلال التطبيق.
      </p>
      <h2>التعويض</h2>
      <p>
        أنت توافق على تعويضنا وحمايته من أي مطالبات أو خسائر أو التزامات أو
        أضرار أو تكاليف أو نفقات (بما في ذلك الرسوم القانونية) الناشئة عن أو
        فيما يتعلق باستخدامك للتطبيق، أو انتهاكك لهذه الشروط، أو انتهاكك لـ أي
        حقوق لأطراف ثالثة.
      </p>
      <h2>قانون الاحكام</h2>
      <p>
        تخضع هذه الشروط والأحكام وتفسر وفقًا للنظام القضائي في المملكة العربية
        السعودية . تخضع أي نزاعات تنشأ عن هذه الشروط أو فيما يتعلق بها للاختصاص
        القضائي الحصري لمحاكم المملكة العربية السعودية. .
      </p>
      <h2>قابلية الفصل</h2>
      <p>
        إذا تبين أن أي بند من هذه الشروط والأحكام غير صالح أو غير قانوني أو غير
        قابل للتنفيذ، فإن الأحكام المتبقية تظل سارية المفعول والتأثير الكامل.
      </p>
      <h2>تعديل الشروط</h2>
      <p>
        نحن نحتفظ بالحق في تعديل أو تحديث هذه الشروط والأحكام في أي وقت. وستكون
        أي تغييرات سارية فور نشر الشروط المعدلة داخل التطبيق. إن استمرارك في
        استخدام التطبيق بعد هذه التغييرات يشكل موافقتك على الشروط المعدلة.
      </p>
      <p>
        إذا كان لديك أي أسئلة أو استفسارات بشأن هذه الشروط والأحكام، يرجى
        الاتصال بنا على support@soumha.com.
      </p>
      <p>
        <strong>آخر تحديث:</strong> 02 أكتوبر 2024
      </p>
    </main>
  );
}
